import React from "react";
import "../Style/About.css";
import "../Style/Boy.css";
import img3 from "../Assist/image 1356 .png";
import img2 from "../Assist/image 1356 .png";
import img1 from "../Assist/ENTER APP.png";
import inscriptions from "../Assist/inscriptions.png";

import { MesonToButton } from "@mesonfi/to";

export function Button() {
  const onCompleted = (data) => {
    // when a cross-chain transfer is successful
    // console.log(data)
  };

  return;
}

const Doddles = () => {
  return (
    <div className="container mt-5" data-aos="fade-up">
      <div className="row  px-5 mt-lg-5">
        <div className="col-12 col-md-6 textMargin">
          <div className="doodlesbrc">
            <h1 className="text-light">
              <span style={{ color: "rgb(255, 214, 0)" }}>1,000</span> Doodles
              inscribed <br /> forever on the Bitcoin blockchain
            </h1>
            <p className="py-2" style={{ color: "#ddd" }}>
              BitCoinVerse Doodle NFTs are the first ever highly byte-perfect
              uploads of the original doodles onto the Bitcoin Blockchain using
              Ordinals.
            </p>

            <div className="img doddle-button">
              <a
                href="https://legacy.bitcoinverse.tech/inscriptions.html"
                target="_blank"
              >
                <img
                  src={inscriptions}
                  alt=""
                  className=""
                  width={"auto"}
                  height={40}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="boy mt-5">
            <div className=" d-block d-md-none doddles-img ">
              <img src={img3} alt="" className="img-fluid" />
            </div>
            <div className="boy1 d-none d-md-block ">
              <img
                src={img3}
                alt=""
                className="img-fluid"
                style={{
                  minWidth: "360px",
                }}
              />
              <img
                src={img3}
                alt=""
                className="img-fluid"
                style={{
                  minWidth: "350px",
                }}
              />
              <img
                src={img3}
                alt=""
                className="img-fluid"
                style={{
                  minWidth: "350px",
                }}
              />
              <img
                src={img3}
                alt=""
                className="img-fluid"
                style={{
                  minWidth: "350px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Doddles;
