import React, { useState } from "react";
import { BiPlus, BiMinus } from "react-icons/bi";
import left from "../Assist/trending_assets/LEFT.png";
import right from "../Assist/trending_assets/RIGHT.png";
import "../Style/Faq.css";

function CollapsibleBox({ title, content }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="collapsible-box">
      <div className="box-header" onClick={handleToggle}>
        <h3 className="box-title">{title}</h3>
        {isExpanded ? (
          <BiMinus className="box-icon" />
        ) : (
          <BiPlus className="box-icon" />
        )}
      </div>
      {isExpanded && <div className="box-content">{content}</div>}
    </div>
  );
}

function FAQ() {
  const boxData = [
    {
      id: 1,
      title: "What is BitCoinVerse?",
      content:
        "The growth of Decentralized Finance, IDO sales on Bitcoin side chains, and Bitcoin NFTs is expected to accelerate rapidly, but with fragmentation looming as a potential obstacle. To establish Bitcoin as the bedrock of DeFi and a safer web3, it is essential to provide support to the projects that leverage bitcoin sidechains and bitcoin NFTs as productive assets. BitCoinVerse offers a comprehensive solution that encompasses all bitcoin side chains and the projects built on them, thus empowering them to thrive.",
    },
    {
      id: 2,
      title: "How do the BitCoinVerse Doodle NFTs work?",
      content: `The BitCoinVerse Doodle NFTs are a collection of 1000 pieces of art inscribed on the bitcoin blockchain and represented by serialized satoshis or "ordinals".

      From the ordinals inscription guide: “Individual sats can be inscribed with arbitrary content, creating Bitcoin-native digital artifacts that can be held in a Bitcoin wallet and transferred using Bitcoin transactions.`,
    },
    {
      id: 3,
      title: "What are inscriptions?",
      content: `Inscriptions are as durable, immutable, secure, and decentralized as Bitcoin itself.”

      For the nitty gritty details, see ordinal theory.`,
    },
    {
      id: 4,
      title:
        "What do I need to receive, send and store BitCoinVerse Doodle NFTs?",
      content: `Technically, any bitcoin wallet will do: being satoshis, ordinals live inside bitcoin addresses. But it is recommended to set up ord, which is a wallet specifically designed to handle ordinals like the BitCoinVerse Doodle NFTs and avoid their inadvertent transfer.`,
    },
  ];

  return (
    <>
      <div className="trendContainer" data-aos="fade-up">
        <img src={left} alt="" />
        <span>FAQS</span>
        <img src={right} alt="" />
      </div>

      <div className="box-grid" data-aos="fade-up">
        {boxData.map((box) => (
          <CollapsibleBox
            key={box.id}
            title={box.title}
            content={box.content}
          />
        ))}
      </div>
    </>
  );
}

export default FAQ;
