import React from "react";

const Heading = ({ heading }) => {
  return (
    <div className="wb">
      <h1
        className="text-center"
        style={{
          color: "gold",
          fontSize: "40px",
          zIndex: "999",
          marginTop: "50px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="85"
          viewBox="0 0 28 85"
          fill="none"
        >
          <line
            x1="2.01172"
            y1="49.8354"
            x2="2.01172"
            y2="69.8354"
            stroke="white"
          />
          <line
            x1="1.76172"
            y1="69.4024"
            x2="26.576"
            y2="83.729"
            stroke="white"
          />
          <line
            x1="1.01172"
            y1="15.8354"
            x2="1.01172"
            y2="35.8354"
            stroke="white"
          />
          <line
            x1="1.26172"
            y1="16.4024"
            x2="27.2425"
            y2="1.40244"
            stroke="white"
          />
        </svg>
        {heading}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="85"
          viewBox="0 0 24 85"
          fill="none"
        >
          <line
            x1="22.9941"
            y1="54.6445"
            x2="22.9941"
            y2="74.6445"
            stroke="white"
          />
          <line
            x1="22.7421"
            y1="74.0787"
            x2="5.73939"
            y2="83.787"
            stroke="white"
          />
          <line
            x1="21.9902"
            y1="12.645"
            x2="21.9902"
            y2="32.645"
            stroke="white"
          />
          <line
            x1="22.2441"
            y1="13.0787"
            x2="1.24439"
            y2="1.07855"
            stroke="white"
          />
        </svg>
      </h1>
    </div>
  );
};

export default Heading;
