import React from "react";
import "../Style/Circle.css";
import c1 from "../Assist/Ellipse 19.png";
import c2 from "../Assist/Ellipse 27.png";
import c3 from "../Assist/Ellipse 31.png";
import btni from "../Assist/ENTER APP.png";
import lo from "../Assist/Link ⏵ logo-dark.png (1).png";
import phn from "../Assist/Group 156.png";
import bo from "../Assist/Group 159.png";
import learnmore from "../Assist/LEARN MORE OVERLAP.png";
import Heading from "./Heading";
const Circle = () => {
  return (
    <>
      <Heading heading={"Our Protocols"} />
      <div
        className="main1 d-none d-xl-flex mt-5"
        data-aos="fade-up"
        style={{ paddingTop: "10px" }}
      >
        <div className="main">
          <div className="circle1">
            <img src={c1} alt="" />
          </div>
          <div className="circle2">
            <img src={c2} alt="" />
          </div>
          <div className="circle3">
            <img src={c3} alt="" />
          </div>
          <div className="circle4" style={{}}>
            <img
              src={c1}
              alt=""
              style={{ backgroundColor: "red", borderRadius: "50%" }}
            />
          </div>
          <div className="circle5" style={{}}>
            <img className="cirimgs" src={lo} alt="" />
          </div>
        </div>

        <div className="box" style={{}}>
          <div className="box11">
            <div className="b11">
              <h2 style={{ marginLeft: "23px", zIndex: "999" }}>
                Bitcoin Protocol
              </h2>
            </div>
            <div className="">
              <p
                className="text-left text-light px-4"
                style={{ zIndex: "999" }}
              >
                "BitcoinVerse's POS protocol enhances Ethereum's capabilities
                with Bitcoin's robust infrastructure. By prioritizing
                interoperability and seamless trading, it transforms weaknesses
                into strengths, offering a fluid, future-proof trading
                experience within the BitcoinVerse ecosystem.
              </p>
            </div>

            <div
              className="bi2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                columnGap: "10px",
              }}
            >
              <a href="https://bitcoinverse.gitbook.io/bitcoinverse/why-bitcoinverse/btv-pos-protocol">
                <img
                  style={{ marginTop: "10px", marginLeft: "40px" }}
                  src={learnmore}
                  alt=""
                />
              </a>
              <img
                src={bo}
                className="bbtn"
                style={{
                  height: "100px",
                  marginTop: "-20px",
                  marginLeft: "40px",
                }}
              />
            </div>

            <span className="shadow"></span>
          </div>
        </div>
        <div className="box">
          <div
            className="box11"
            style={{
              opacity: "0.9",
              marginTop: "-300px",
              marginLeft: "-250%",
              borderRadius: "2px 2px 90px 2px",
              padding: "0.5rem",
              backgroundColor: "rgba(7, 29, 41, 1)",
            }}
          >
            <div className="b11" style={{}}>
              <h2 style={{ marginLeft: "23px", zIndex: "999" }}> BTC Swap</h2>
            </div>
            <div className="">
              <p
                className="text-left text-light px-4"
                style={{ zIndex: "999" }}
              >
                "BCVS Swap Protocol revolutionizes crypto exchanges with direct
                token swaps across blockchains. It simplifies processes,
                offering seamless transactions, enhanced efficiency, and
                increased user flexibility. Enjoy streamlined trading and
                portfolio management with BCVS Swap
              </p>
            </div>

            <div
              className="bi2"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <a href="https://bitcoinverse.gitbook.io/bitcoinverse/why-bitcoinverse/btv-swap-protocol">
                <img style={{ marginTop: "10px" }} src={learnmore} alt="" />
              </a>
              <img
                src={phn}
                style={{
                  height: "100px",
                  marginTop: "-20px",
                  marginLeft: "100px",
                }}
              />
            </div>
            <span className="shadow"></span>
          </div>
        </div>
      </div>
      <div
        className=" w-100 d-block d-xl-none"
        style={{
          background: "#071d29",
        }}
      >
        <div className="container " style={{ marginTop: "30px" }}>
          <div className="">
            <div className="col-12 col-lg-6">
              <div className="box12 mx-auto">
                <div className="b11">
                  <h2 style={{ marginLeft: "23px", zIndex: "999" }}>
                    {" "}
                    Bitcoin Protocol
                  </h2>
                </div>
                <div className="">
                  <p
                    className="text-left text-light px-4"
                    style={{ zIndex: "999" }}
                  >
                    "BitcoinVerse's POS protocol enhances Ethereum's
                    capabilities with Bitcoin's robust infrastructure. By
                    prioritizing interoperability and seamless trading, it
                    transforms weaknesses into strengths, offering a fluid,
                    future-proof trading experience within the BitcoinVerse
                    ecosystem
                  </p>
                </div>

                <div
                  className="bi2"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    columnGap: "10px",
                  }}
                >
                  <img src={btni} alt="" className="img-fluid" />
                  <img src={bo} className="img-fluid" />
                </div>

                <span className="shadow"></span>
              </div>
            </div>
            <div className="col-12 col-lg-6 mt-4">
              <div className="box12 mx-auto">
                <div className="b11">
                  <h2 style={{ marginLeft: "23px", zIndex: "999" }}>
                    {" "}
                    BTC Swap
                  </h2>
                </div>
                <div className="">
                  <p
                    className="text-left text-light px-4"
                    style={{ zIndex: "999" }}
                  >
                    "BCVS Swap Protocol revolutionizes crypto exchanges with
                    direct token swaps across blockchains. It simplifies
                    processes, offering seamless transactions, enhanced
                    efficiency, and increased user flexibility. Enjoy
                    streamlined trading and portfolio management with BCVS Swap
                  </p>
                </div>

                <div
                  className="bi2"
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <img
                    style={{ marginTop: "10px" }}
                    src={btni}
                    alt=""
                    className="img-fluid"
                  />
                  <img src={phn} className="img-fluid" />
                </div>
                <span className="shadow"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Circle;
