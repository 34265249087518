import React from "react";
import "../Style/About.css";
import img1 from "../Assist/ENTER APP.png";
import img2 from "../Assist/LEARN MORE OVERLAP.png";
import img3 from "../Assist/about.png";
import Heading from "./Heading";

const About = () => {
  return (
    <div data-aos="fade-up">
      <div className="container p-4">
        <h1
          className="text-center about-center"
          style={{
            color: "gold",
            fontSize: "40px",
            zIndex: "999",
          }}
        >
          <Heading heading="About us" />
        </h1>
        <div className="row row-about">
          <div className="col-12 col-md-7">
            <div className="pb-5 my-5">
              <p className="text-light">
                BitCoinVerse is a visionary platform, which unites various
                facets of decentralized finance, IDO sales, and NFTs on Bitcoin
                side chains. With its commitment to inclusivity, innovation, and
                user accessibility, BitCoinVerse positions itself as a leading
                force in shaping the future of decentralized finance within the
                Bitcoin ecosystem.
              </p>

              <p className="text-light">
                Our platform is designed to simplify the DeFi ecosystem on
                Bitcoin chains, offering users a central hub to access a wide
                range of projects and services.
              </p>
              <div className="img pt-3 about-button">
                <a href="https://bitcoinverse.gitbook.io/bitcoinverse/bitcoinverse-token">
                  <img src={img2} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={img3} className="img-fluid img-div" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
