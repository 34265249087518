import React from "react";
import chains from "../Assist/chains.png";
import logos from "../Assist/chainsM.png";
import circle1 from "../Assist/circle1.png";
import circle2 from "../Assist/circle2.png";
import "../Style/Chains.css";
import { MesonToButton } from "@mesonfi/to";

const SideChains = () => {
  return (
    <div className="sidechains container" data-aos="fade-up">
      <div className="sideChainsContainer">
        <h1>
          We are the <span style={{ color: "gold" }}>leading</span> supporter of{" "}
          <span style={{ color: "gold" }}>Bitcoin Side chains</span>
        </h1>
        <p>
          The Bitcoin-Verse emerges as a vanguard supporter of groundbreaking
          Bitcoin side chains. Among its esteemed contributors stand
          <span className="chainsName">
            &nbsp;
            <a href="https://bitfinity.network/" target="_blank">
              Bitfinity, &nbsp;
            </a>
            <a href="https://www.bitsmiley.io/" target="_blank">
              Bitsmiley, &nbsp;
            </a>
            <a href="https://botanixlabs.xyz/" target="_blank">
              Botanixlabs, &nbsp;
            </a>
            <a href="https://mintlayer.org/" target="_blank">
              Mintlayer, &nbsp;
            </a>
            <a href="https://bsquared.network/" target="_blank">
              Bsquared, &nbsp;
            </a>
            <a href="https://merlinchain.io/" target="_blank">
              Merlinchain
            </a>
            &nbsp;
          </span>
          and numerous others, collectively driving the evolution of
          decentralized finance (DeFi) and beyond.
        </p>
        <MesonToButton />
      </div>
      <div className="chainsContainer" data-aos="fade-up">
        <img
          src={circle1}
          alt="circle"
          className="circleChains firstSpin width1"
        />
        <img
          src={circle2}
          alt="circle"
          className="circleChains secondSpin width2"
        />
        <img src={logos} alt="chains" className="chains logosImage" />
      </div>
    </div>
  );
};

export default SideChains;
