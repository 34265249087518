import React, { useEffect, useState } from "react";
import "../Style/Component.css";
import "../Style/Circle.css";
import Circle from "./Circle";
import logo from "../Assist/Link ⏵ logo-dark.png (1).png";
import { HiMenu } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import Typewriter from "./Typewriter";
import { Link } from "react-scroll";
// import { HashLink } from 'react-router-hash-link';
const Containerss = () => {
  const [scrolled, setScrolled] = useState(false);
  const [scrolled2, setScrolled2] = useState(false);

  const handleScroll = () => {
    const isScrolled = window.scrollY > 50;
    if (isScrolled !== scrolled) {
      setScrolled(isScrolled);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll2 = () => {
    const isScrolled2 = window.scrollY > 200;
    if (isScrolled2 !== scrolled2) {
      setScrolled2(isScrolled2);
    } else {
      setScrolled2(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll2);
    return () => {
      window.removeEventListener("scroll", handleScroll2);
    };
  }, []);

  const [open, setopen] = useState(false);
  const parth = "/";

  return (
    <>
      <div
        id="header"
        style={{ marginBottom: `${scrolled ? "8rem" : "2rem"}` }}
        className={`${scrolled ? "scroll1 " : ""}${
          scrolled2 ? "scroll2 " : ""
        }${!scrolled && !scrolled2 ? "unscroll " : ""}`}
      >
        <header
          className={`header ${scrolled ? "scrolled" : ""}`}
          style={{ height: "90px" }}
        >
          <div style={{ height: "90px" }}>
            <div className="container" style={{ height: "90px" }}>
              <div className="navbar-2">
                <div
                  className="burger d-black d-xl-none"
                  id="burger"
                  onClick={() => setopen(!open)}
                >
                  {!open ? (
                    <HiMenu className="text-light" style={{ fontSize: 25 }} />
                  ) : (
                    <IoCloseSharp
                      className="text-light"
                      style={{ fontSize: 25 }}
                    />
                  )}
                </div>
                <div className="brand my-3">
                  <a href="https://bitcoinverse.tech">
                    <img src={logo} alt="" className="img-fluid w-75" />
                  </a>
                </div>
                <span className="overlay"></span>
                <div
                  className={` ${open ? "menu is-active" : "menu"}`}
                  id="menu"
                >
                  <ul className="menu-inner">
                    <li
                      className="menu-item px-1  d-black d-xl-none "
                      style={{ textAlign: "end" }}
                    >
                      <span
                        className="menu-link  text-danger"
                        style={{ fontSize: "24px", cursor: "pointer" }}
                        onClick={() => setopen(!open)}
                      >
                        {!open ? (
                          <HiMenu
                            className="text-light"
                            style={{ fontSize: 25 }}
                          />
                        ) : (
                          <IoCloseSharp
                            className="text-light"
                            style={{ fontSize: 25 }}
                          />
                        )}
                      </span>
                    </li>
                    <li className="menu-item px-3">
                      <span
                        className="menu-link "
                        style={{
                          color: `${
                            parth === "/" ? "hsl(13, 83%, 50%)" : "#000"
                          }`,
                        }}
                        onClick={() => {
                          setopen(!open);
                        }}
                      >
                        <a href="#">HOME</a>
                      </span>
                    </li>
                    <li className="menu-item px-3">
                      <span className="menu-link ">
                        <a
                          href="https://mint.bitcoinverse.tech"
                          target="_blank"
                        >
                          MINT
                        </a>
                      </span>
                    </li>
                    <li className="menu-item px-3" onClick={() => {}}>
                      <span
                        href="#"
                        class="menu-link "
                        // data-toggle="dropdown"
                      >
                        <Link to="aboutScroll">ABOUT US</Link>
                      </span>
                      <ul
                        id="products-menu"
                        class="dropdown-menu clearfix "
                        role="menu"
                      >
                        <ul
                          className="dropdown-menu1 d-block "
                          style={{ top: -3, left: -3, width: 320 }}
                        >
                          {/* <li>
                            <a href="">Home</a>
                            <a href="">Consultation</a>
                            <a href="">Cryptocurrency</a>
                            <a href="">Crypto wallet</a>
                            <a href="">Exchange</a>
                          </li>
                          <li>
                            <a href="">NFT Wallet</a>
                            <a href="">Forex exchange</a>
                            <a href="">Pancakeswap</a>
                            <a href="">Cortex DAO</a>
                          </li>
                          <li>
                            <a href="">Home</a>
                            <a href="">Consultation</a>
                            <a href="">Cryptocurrency</a>
                          </li> */}
                        </ul>
                      </ul>
                    </li>
                    <li
                      className="menu-item px-3"
                      onClick={() => {
                        setopen(!open);
                      }}
                    >
                      <span
                        className="menu-link "
                        style={{
                          color: `${
                            parth === "/Services" ? "hsl(13, 83%, 50%)" : "#000"
                          }`,
                        }}
                      >
                        <Link to="wybtvScroll">OUR PRODUCTS</Link>
                      </span>
                    </li>
                    <li className="menu-item px-3" onClick={() => {}}>
                      <span
                        className="menu-link "
                        style={{
                          color: `${
                            parth === "/Services" ? "hsl(13, 83%, 50%)" : "#000"
                          }`,
                        }}
                      >
                        <Link to="faqsScroll">FAQS</Link>
                      </span>
                    </li>

                    <li className="menu-item px-3" onClick={() => {}}>
                      <a
                        href="https://legacy.bitcoinverse.tech/inscriptions.html"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <span className="menu-link ">
                          <Link to="{{}}">INSCRIPTIONS</Link>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>{" "}
                <div className="main-nav">
                  <ul
                    id="nav"
                    class="nav nav-pills clearfix right d-none d-lg-flex m-auto"
                    role="tablist"
                  >
                    <li className="px-3">
                      <span
                        href="#"
                        className="text-light"
                        style={{ cursor: "pointer" }}
                      >
                        <Link to={{}}>HOME</Link>
                      </span>
                    </li>
                    <li className="px-3">
                      <span
                        href="#"
                        className="text-light"
                        style={{ cursor: "pointer" }}
                      >
                        <Link to="aboutScroll">ABOUT US</Link>
                      </span>
                    </li>
                    <li className="px-3">
                      <a
                        href="https://mint.bitcoinverse.tech"
                        className="text-light"
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        target="_blank"
                      >
                        MINT
                      </a>
                    </li>
                    <li className="px-3">
                      <span
                        href="#"
                        className="text-light"
                        style={{ cursor: "pointer" }}
                      >
                        <Link to="wybtvScroll">OUR PRODUCTS</Link>
                      </span>
                    </li>

                    <li className="px-3">
                      <span
                        href="#"
                        className="text-light"
                        style={{ cursor: "pointer" }}
                      >
                        <Link to="faqsScroll">FAQS</Link>
                      </span>
                    </li>
                    <li className="px-3">
                      <span
                        href="#"
                        className="text-light"
                        style={{ cursor: "pointer" }}
                      >
                        <a
                          href="https://legacy.bitcoinverse.tech/inscriptions.html"
                          style={{ color: "#fff", textDecoration: "none" }}
                        >
                          INSCRIPTIONS
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="launch-app-btn ms-auto d-flex justify-content-end">
                  <a href="https://launchpad.bitcoinverse.tech" target="_blank">
                    <img
                      src={require("../Assist/s.png")}
                      className="img-fluid w-75"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div
          className="container"
          style={{ display: scrolled ? "none" : "block" }}
        >
          <div className="row mainsection">
            <div className="col-12 col-lg-6 position-relative">
              <h1 className="main-heding">
                BUILD THE FUTURE WITH <br />{" "}
                <span style={{ color: "#FFD600" }}>
                  <Typewriter text="BITCOINVERSE" />
                </span>
              </h1>
            </div>
            <div className="col-12 col-lg-6 position-relative hero-image ">
              <a href="https://launchpad.bitcoinverse.tech" target="_blank">
                <img
                  src={require("../Assist/ENTER APP.png")}
                  alt="ENTER APP"
                  className="img-hero-1"
                />
              </a>
              <a
                href="https://bitcoinverse.gitbook.io/bitcoinverse/"
                target="_blank"
              >
                <img
                  src={require("../Assist/LEARN MORE OVERLAP.png")}
                  alt="LEARN MORE OVERLAP"
                  className="img-hero-1"
                />
              </a>
              {/* <a href="https://launchpad.bitcoinverse.tech" target="_blank">
                <img
                  // src={require("../Assist/BUTTON (1).png")}
                  src={require("../Assist/ENTER APP.png")}
                  className="img-fluid d-block m-auto img-2 hero-image"
                  style={{ right: 220, width: "220px", minWidth: "200px" }}
                />
              </a>
              <a
                href="https://bitcoinverse.gitbook.io/bitcoinverse/"
                target="_blank"
              >
                <img
                  src={require("../Assist/LEARN MORE OVERLAP.png")}
                  className="img-fluid d-block m-auto img-2"
                  style={{ right: 0, width: "220px", minWidth: "200px" }}
                />
              </a> */}
              {/* <img src={require('../Assist/BUTTON (1).png')} className='img-fluid d-block m-auto img-2' style={{ right: 0, width: "370px", minWidth: '300px' }} /> */}
            </div>
          </div>
        </div>

        {scrolled && !scrolled2 && (
          <div className="hidden-div">
            <h3 className="left-h3">
              $BCVS STANDS-OUT AS THE FIRST EVER TOKEN THAT EXTENDS ITS SUPPORT
              TO ALL BITCOIN SIDE CHAINS
            </h3>
            <h3 className="right-h3">
              SUCH AS ERC20 AND STX ETC. IT OFFERS SUBSTANTIAL UTILITIES WITH A
              SUPERIOR LEVEL OF SECURITY
            </h3>
          </div>
        )}
        {scrolled2 && (
          <div className="scrolled2-div">
            <h1
              className="text-center"
              style={{ color: "gold", zIndex: "999" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="85"
                viewBox="0 0 28 85"
                fill="none"
              >
                <line
                  x1="2.01172"
                  y1="49.8354"
                  x2="2.01172"
                  y2="69.8354"
                  stroke="white"
                />
                <line
                  x1="1.76172"
                  y1="69.4024"
                  x2="26.576"
                  y2="83.729"
                  stroke="white"
                />
                <line
                  x1="1.01172"
                  y1="15.8354"
                  x2="1.01172"
                  y2="35.8354"
                  stroke="white"
                />
                <line
                  x1="1.26172"
                  y1="16.4024"
                  x2="27.2425"
                  y2="1.40244"
                  stroke="white"
                />
              </svg>
              BITCOIN LAUNCHPAD
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="85"
                viewBox="0 0 24 85"
                fill="none"
              >
                <line
                  x1="22.9941"
                  y1="54.6445"
                  x2="22.9941"
                  y2="74.6445"
                  stroke="white"
                />
                <line
                  x1="22.7421"
                  y1="74.0787"
                  x2="5.73939"
                  y2="83.787"
                  stroke="white"
                />
                <line
                  x1="21.9902"
                  y1="12.645"
                  x2="21.9902"
                  y2="32.645"
                  stroke="white"
                />
                <line
                  x1="22.2441"
                  y1="13.0787"
                  x2="1.24439"
                  y2="1.07855"
                  stroke="white"
                />
              </svg>
            </h1>
            <h5>
              Unleash the Power of next-Generation Decentralized Applications on
              Bitcoin
            </h5>
            <p>
              Transform your Bitcoin into a Thriving Ecosystem : Empower
              innovative projects, revolutionize finance, and redefine cultural
              norms.
            </p>
            <a href="https://launchpad.bitcoinverse.tech" target="_blank">
              <img
                src={require("../Assist/container-app.png")}
                alt="Image"
              ></img>
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default Containerss;
