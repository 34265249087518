import React from "react";
import logo from "../Assist/footer/logo-dark.png.png";
import { FaTwitter, FaTelegram, FaDiscord } from "react-icons/fa";
import "../Style/footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer-background">
        <footer className="footer-section">
          <div className="container">
            <div className="footer-content pt-5 pb-3">
              <div className="row">
                <div
                  className="col-12 col-lg-6"
                  style={{ borderRight: "1px solid #fff" }}
                >
                  <div className="footer-widget">
                    <div className="footer-logo">
                      <a href="https://bitcoinverse.tech">
                        <img src={logo} className="img-fluid" />
                      </a>
                    </div>
                    <div className="footer-text">
                      <p className="text-light">
                        $BCVS is an open-source protocol layer of the ecosystem,
                        a highly secure and useful utility that enables users to
                        participate in the BitCoinVerse ecosystem.
                      </p>
                    </div>
                    <div className="footer-social-icon">
                      <h3 className="socialsLO py-2">OUR SOCIALS</h3>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <a href="https://twitter.com/BitCoin_Verse">
                            <FaTwitter
                              className="me-md-3"
                              style={{ fontSize: "25px" }}
                            />
                          </a>
                          <a href="https://t.me/BitCoinVerse_Official">
                            <FaTelegram
                              className="mx-md-3"
                              style={{ fontSize: "25px" }}
                            />
                          </a>
                          <a href="#">
                            <FaDiscord
                              className="mx-md-3"
                              style={{ fontSize: "25px" }}
                            />
                          </a>
                        </div>
                        <div>
                          <a
                            href="https://bitcoinverse.gitbook.io/bitcoinverse/"
                            target="_blank"
                          >
                            <img
                              src={require("../Assist/LEARN MORE OVERLAP (1).png")}
                              className="img-fluid w-75"
                              id="whitelabel"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="d-flex justify-content-center align-items-center">
                    <input
                      className="inp mr-2 footer-input"
                      placeholder="ENTER YOUR MAIL"
                    />
                    <img
                      src={require("../Assist/LEARN MORE OVERLAP (2).png")}
                      className="img-fluid aacc footer-img"
                      width={150}
                      style={{ cursor: "pointer" }}
                      alt="Learn More"
                    />
                  </div>
                  <div className="footer-widget pt-1">
                    <div className="row ps-lg-5 ms-lg-5">
                      <div className="col-12 col-lg-6">
                        <h3 className="socialsLO py-2">ABOUT US</h3>
                        <a
                          href="https://bitcoinverse.gitbook.io/bitcoinverse/"
                          style={{ textDecoration: "none" }}
                        >
                          <p className="text-light">Whitepaper</p>
                        </a>
                        <p className="text-light">Blog</p>
                        <p className="text-light">Activity</p>
                        <a
                          href="https://legacy.bitcoinverse.tech/contact.html"
                          style={{ textDecoration: "none" }}
                        >
                          <p className="text-light">Contact</p>
                        </a>
                      </div>
                      <div className="col-12 col-lg-6">
                        <h3 className="socialsLO py-2">SUPPORT</h3>
                        <p className="text-light">Help & Support</p>
                        <p className="text-light">Brand Assets</p>
                        <p className="text-light">Docs</p>
                        <p className="text-light">Privacy</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="pt-4" style={{ color: "#ddd" }}>
                  2024 RESERVED BY BITCOINVERSE INC
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
