import React from "react";
import "../Style/bcvsUtils.css";
import { accessory, board, box, nft, vote, music } from "../Assist/Utility";
import Heading from "./Heading";

const items = [
  {
    img: nft,
    heading: "Doodles NFTs",
    data: "BitcoinVerse Doodle NFTs is going to come up with a Marketplace for Bitcoin NFTs where people can trade their bitcoin NFTs at very less fee.",
    borderType: "borderboxRight",
  },
  {
    img: box,
    heading: "Mystery Box",
    data: "BitcoinVerse Doodle NFTs holders get a chance to win a mystery box on a monthly basis which contains a NFT with more rarity",
    borderType: "borderboxRight ",
  },

  {
    img: vote,
    heading: "Doodles Vote",
    data: "Each Doodle allows its owner to vote for experiences and activations paid for by  the Doodles Community Treasury.",
    borderType: " border-bottom",
  },
  {
    img: music,
    heading: "Doodles Music",
    data: "Doodles feature top artists' tunes, unlock and play at your own fest. Music to create, from legends' best.",
    borderType: "special",
  },
  {
    img: accessory,
    heading: "Doodle Accessories",
    data: "Characters and wearables from Doodles  can easily be bought, traded, or sold.",
    borderType: "borderbox",
  },
  {
    img: board,
    heading: "Doodle Comics",
    data: "Doodle comic series will be aired and Doodle owners can watch it with their NFTs as a ticket",
    borderType: "borderbox",
  },
];

const Card = ({ img, heading, data, borderType }) => {
  return (
    <div className={`utilscard ${borderType}  `}>
      <img src={img} alt="card" />
      <h3>{heading}</h3>
      <p>{data}</p>
    </div>
  );
};

const BcvsUtils = () => {
  return (
    <>
      <Heading heading={"NFT UTILITIES"} />
      <div className="container bcvs" data-aos="fade-up">
        {items.map((item, index) => {
          return (
            <Card
              key={index}
              img={item.img}
              heading={item.heading}
              data={item.data}
              borderType={item.borderType}
            />
          );
        })}
      </div>
    </>
  );
};

export default BcvsUtils;
