import React, { useEffect } from "react";
import About from "./Component/About";
import Wybtv from "./Component/Wybtv";
import Doddles from "./Component/Doddles";
import Utilities from "./Component/Utilities";
import Circle from "./Component/Circle";
import Main from "./Component/Main";
import Containerss from "./Component/Containerss";
import Boyiamge from "./Component/Boyiamge";
import Utilitiesss from "./Component/Utilitiesss";
import Trending from "./Component/Trending";
import Aos from "aos";
import FAQ from "./Component/FAQ";
import { Partner } from "./Component/Partner";
import Footer from "./Component/footer";
import BcvsUtils from "./Component/BcvsUtils";
import SideChains from "./Component/SideChains";

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);
  return (
    <>
      <div style={{ background: "rgba(7, 29, 41, 1)" }}>
        <Containerss />
        <SideChains />
        <Circle />
        <div className="aboutScroll">
          <About />
        </div>
        <div className="wybtvScroll">
          <Wybtv />
        </div>
        <Doddles />
        <BcvsUtils />
        <Trending />
        <div className="faqsScroll">
          <FAQ />
        </div>
        <Partner />
        <Footer />
      </div>
    </>
  );
};

export default App;
