import React from "react";
import left from "../Assist/trending_assets/LEFT.png";
import right from "../Assist/trending_assets/RIGHT.png";
import partner from "../Assist/partner/partners.png";
import partner2 from "../Assist/partner/partners2.png";
import partner3 from "../Assist/partner/partners3.png";
import partner4 from "../Assist/partner/partners4.png";
import mintlayer from "../Assist/partner/mintlayer.png";
import ord from "../Assist/partner/ord.png";
import bc from "../Assist/partner/bscapital.png";
import "../Style/partners.css";

import { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "../Style/Carousel.css";

// import required modules
import { Navigation } from "swiper/modules";

export const Partner = () => {
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 400) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 800) {
        setSlidesPerView(1);
      } else {
        setSlidesPerView(4);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const partner_image = [
    { img: partner, text: "partner1" },
    { img: partner2, text: "partner2" },
    { img: mintlayer, text: "partner3" },
    { img: ord, text: "partner4" },
    { img: bc, text: "partner5" },
  ];

  return (
    <>
      <div className="trendContainer">
        <img src={left} alt="" />
        <span>OUR PARTNERS</span>
        <img src={right} alt="" />
      </div>

      {/* <div className="box-grid"> */}
      <div className="partners_section" data-aos="fade-up">
        {partner_image.map((img, index) => (
          <div key={index} className="">
            <img alt={img.text} src={img.img} className="partners_img" />
          </div>
        ))}
      </div>
      {/* </div> */}
      {/* <Swiper
        slidesPerView={slidesPerView}
        spaceBetween={200}
        cssMode={true}
        navigation={true}
        // pagination={{
        //   clickable: true,
        // }}
        modules={[Navigation]}
        className="mySwiper"
        centeredSlides={true}
      >
        <SwiperSlide>
          <center>
            <img src={partner} alt="" />
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <center>
            <img src={partner2} alt="" />
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <center>
            <img src={partner} alt="" />
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <center>
            <img src={partner} alt="" />
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <center>
            <img src={partner2} alt="" />
          </center>
        </SwiperSlide>
        <SwiperSlide>
          <center>
            <img src={partner} alt="" />
          </center>
        </SwiperSlide>
      </Swiper> */}
    </>
  );
};
